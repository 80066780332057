@import 'assets/css/constants.scss';

* {
  font-family: Avenir, Arial, Helvetica, sans-serif !important;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
  outline: none;

  body {
    overflow: hidden;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    #app {
      overflow: auto;
      height: calc(100vh - $topbar-height);
      height: calc(100svh - $topbar-height);
      margin-top: $topbar-height;
    }
  }
}

.page {
  height: calc(100vh - $topbar-height);
  height: calc(100svh - $topbar-height);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #2c2c2c;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
