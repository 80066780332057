.module-page {
  position: relative;
  background-color: rgb(159, 159, 159);
  height: 100%;

  .loader {
    z-index: 9;
  }

  svg:first-of-type {
    > rect {
      fill: rgb(159, 159, 159);
    }
    > g > rect {
      fill: rgb(159, 159, 159);
    }
  }

  div[role=navigation], div[role=toolbar] {
    display: none !important;
  }
}