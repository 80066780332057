@import 'assets/css/constants.scss';

header {
  background-color: #050D12;
  height: $topbar-height;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 990;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > *, .MuiButtonBase-root {
    color: white;
  }

  .MuiButtonBase-root {
    font-weight: 800;
    text-transform: capitalize;
  }

  .logos {
    display: flex;
    gap: 28px;
  }
}

@media screen and (max-width: 950px) {
  header .logos > img {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  header {
    .logos {
      display: none;
    }
  }
}
