@import "assets/css/constants";

.module-select {
  background-color: $color-white;
  padding: 32px;
  border-radius: 16px;
  box-shadow: $box-shadow-light;
  text-align: left;

  .section-header {
    h2 {
      font-size: 24px;
      font-weight: 700;
      color: $color-black;
      margin-bottom: 28px;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      color: $color-secondary-dark;
      line-height: 24px;
      margin-bottom: 18px;
    }
  }

  .option-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 40svh;
    overflow-y: auto;

    .list-item {
      button.transparent-button {
        width: 510px;
        height: 96px;
        cursor: pointer;
        border-radius: 11px;
        border: 1px solid #dcdcd9;
        display: flex;
        align-items: center;
        padding: 0 16px;
        background: $color-white;
        margin: 2px;

        .title-info {
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 100%;
          align-items: center;
          text-align: left;
          gap: 10px;
          font-size: 24px;
          font-weight: 700;
          color: $color-black;
          span:first-of-type {
            overflow: hidden;
          }

          span.tag {
            font-size: 16px;
            line-height: 16px;
            font-weight: 700;
            border-radius: 4px;
            color: $color-white;
            padding: 6px 8px 6px 8px;

            &.ag {
              background-color: $color-main;
            }
            &.ng {
              background-color: #8817e0;
            }
          }
        }
        &.selected {
          outline: 2px solid #1775e0;
        }
      }
      button[disabled] {
        .title-info {
        color: #9c9c9c;
        cursor: not-allowed;
        }
      }
    }
  }
  .simple-button {
    margin-top: 44px;
    margin-bottom: 24px;
  }
}
