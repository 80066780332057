@import "assets/css/constants.scss";

.period-wrapper {
  width: 100%;
  height: 100%;
  background-color: #2E3338;
  display: flex;
  justify-content: center;
  padding: 20px;

  .period {
    width: 413px;
    border-radius: 16px;
    box-shadow: $box-shadow-light;
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 46px;
  
    .header {
      display: flex;
      flex-direction: column;
      gap: 8px;
  
      h2 {
        font-size: 24px;
        line-height: 33px;
        font-weight: 700;
        color: $color-black;
        width: 308px;
      }
  
      .available-data {
        font-size: 14px;
        line-height: 21px;
        color: $color-main;
        font-style: italic;
        font-weight: 700;
      }
  
      .description {
        font-size: 16px;
        line-height: 24px;
        color: $color-gray;
      }
    }
  
    .calendar-wrapper {
      box-shadow: $box-shadow-medium;
      border-radius: 11px;
      select {
        color: $color-very-dark-gray;
        font-weight: 700;
        font-size: 17px;
        line-height: 17px;
        letter-spacing: -0.01em;
        text-align: center;
        text-transform: capitalize;
      }
  
      .rdrCalendarWrapper {
        border-radius: 11px;
        background-color: $color-light-gray;
      }
  
      .rdrMonthAndYearWrapper {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        .rdrMonthPicker,
        .rdrYearPicker {
          background-color: $color-white;
          box-shadow: $box-shadow-dark;
          border-radius: 4px;
          margin: 0px;
        }
  
        .rdrMonthAndYearPickers {
          gap: 1px;
          select {
            padding: 7px 8px 7px 8px;
            appearance: none;
            background: transparent;
          }
        }
  
        .rdrNextPrevButton {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $color-white;
          width: 31px;
          height: 31px;
          border-radius: 56px;
          box-shadow: $box-shadow-dark;
  
          &:hover {
            background-color: #f2f2f2;
          }
        }
  
        .rdrNextPrevButton i {
          margin: 0;
        }
      }
      .rdrDayStartPreview {
        border-radius: 3px;
      }
  
      .rdrCalendarWrapper:not(.rdrDateRangeWrapper)
        .rdrDayHovered
        .rdrDayNumber:after {
        border-radius: 3px;
        top: -5px;
    bottom: -5px;
      }
  
      .rdrDayToday .rdrDayNumber span:after {
        bottom: 1px;
      }
      .rdrDayNumber {
        font-weight: 900;
      }
      .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
        top: 0px;
        bottom: 0px;
    }
  
      .rdrMonth {
        padding: 0px 17px 17px 17px;
  
        .rdrWeekDays {
          display: flex;
          justify-content: space-between;
          padding: 0px 14px 0px 14px;
          .rdrWeekDay {
            font-weight: 500;
            color: $color-dark-gray;
            text-transform: capitalize;
            width: 35px;
          }
        }
  
        .rdrDays {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 3px;
          padding: 9px 14px 0px 14px;
  
          .rdrDay:not(.rdrDayPassive) {
            background-color: $color-white;
            color: $color-black;
            border-radius: 4px;
            box-shadow: $box-shadow-dark;
          }
          .rdrDay {
            width: 35px;
            height: 30px;
            font-weight: 900;
          }
  
          .rdrSelected {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            top: 0;
            left: 0;
            background-color: $color-main;
          }
        }
      }
    }
  
    .time-duration-wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
  
      .time-picker,
      .duration-picker {
        display: flex;
        flex-direction: column;
        gap: 12px;
  
        label {
          display: block;
          font-size: 12px;
          line-height: 12px;
          color: $color-medium-gray;
        }
  
        .select-wrapper {
          .select {
            width: 100%;
            min-height: 56px;
            padding: 16px;
            font-size: 16px;
            line-height: 16px;
            border-radius: 4px;
            border: 1px solid #ccd4e3;
            color: $color-pure-black;
            appearance: none;
            background: transparent;
          }
        }
  
        fieldset {
          border: 1px solid #ccd4e3;
        }
  
        button {
          color: $color-pure-black;
        }
  
        input {
          color: $color-pure-black;
        }
      }
    }
  
    .simple-button {
      width: 320px;
      height: 56px;
      font-size: 16px;
    }
  }
  
  .rdrWeekDay {
    flex-basis: auto;
  }
  
  .rdrMonthAndYearPickers {
    flex: none;
  }  
}
