@import 'assets/css/constants.scss';

@mixin button-modifier($color, $fontColor, $borderColor, $class) {
  &.button--#{$class} {
    background: $color;
    color: $fontColor;
    border: 1px solid $borderColor;

    &:hover {
      background: darken($color, 5%);
    }
  }
}

.MuiButtonBase-root.simple-button {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 56px;
  box-shadow: none;
  font-size: 18px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: unset;
  text-transform: unset;
  border-radius: 8px;
  width: 100%;

  &:hover {
    outline: 0;
    text-decoration: none;
    box-shadow: none;
  }

  @include button-modifier($color-main, #FFF, transparent, primary);
  @include button-modifier(#FFF, black, #E6EAEE, light);
  @include button-modifier(#FFF, $color-main, transparent, borderLess);

  &.disabled {
    opacity: 0.5;
    pointer-events: all;

    &:hover {
      background: $color-main;
      cursor: not-allowed;
    }
  }
}
