.module-page {
  position: relative;
  background-color: rgb(159, 159, 159);
  height: 100%;

  .itineraries {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: white;
    z-index: 1;
    border-radius: 5px;
    overflow: hidden;

    p {
      padding: 6px 10px;
      background-color: #07F;
      color: white;
      font-size: 14px;
      font-weight: bold;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      li {
        text-align: center;
        font-size: 14px;
      }
    }
  }

  .loader {
    z-index: 9;
  }

  svg:first-of-type {
    > rect {
      fill: rgb(159, 159, 159);
    }
    > g > rect {
      fill: rgb(159, 159, 159);
    }
  }

  div[role=navigation], div[role=toolbar] {
    display: none !important;
  }
}