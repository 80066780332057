.period-indicator {
  display: flex;
  gap: 16px;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 9;

  p {
    height: 41px;
    display: flex;
    padding: 12px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #07F;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    font-weight: 500;
  }

  .MuiButtonBase-root.simple-button {
    width: unset;
    height: 41px;
    min-height: unset;
  }
}