@import "assets/css/constants";

.breadcrumb {
  display: flex;
  align-items: center;
  gap: 16px;

  button {
    background: none;
    border: none;
    color: #ffffff94;
    cursor: pointer;
    font-size: 16px;
    font-weight: 800;
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
      color: #fff;
    }

    span.ihm-tag {
      font-size: 16px;
      line-height: 16px;
      font-weight: 700;
      border-radius: 4px;
      padding: 6px 8px 6px 8px;

      &.ag {
        background-color: $color-main;
      }
      &.ng {
        background-color: #8817e0;
      }       
    }
  }
}