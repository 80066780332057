/* * * * COLORS * * * */
$color-main: #1775E0;
$color-secondary: #F4F3F7;
$color-secondary-dark: #2C2C2C;
$color-white: #FFF;
$color-black: #050D12;
$color-pure-black: #000000;
$color-error: #FF0000;
$color-deep-blue-gray: #263340;
$color-slate-blue: #536B83;
$color-bright-blue: #0077FF;
$color-light-gray-blue: #E6EAEE;
$color-gray: #5A5A5A;
$color-medium-gray: #767676;
$color-light-gray: #F5F5F5;
$color-dark-gray: #1F1F1F;
$color-very-dark-gray: #141414;


/* * * * SIZES * * * */
$topbar-height: 72px;
$project-header-height: 56px;
$side-menu-width: 320px;
$side-menu-width-mobile: 200px;

/* * * * SHADOWS * * * */
$box-shadow-light: 0 8px 10px rgba(0, 0, 0, 0.05);
$box-shadow-dark: 0px 1px 1px 0px rgba(0, 14, 51, 0.05);
$box-shadow-medium: 0px 1px 1px 0px rgba(0, 14, 51, 0.25);